import { parse } from 'querystring';

import React, { useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { Navigation } from 'main/components';
import { useUpdateModalCookie } from 'main/hooks/useUpdateModalCookie';

const Layout: React.FC = ({ children }) => {
  const { value, removeUpdateCookie } = useUpdateModalCookie();
  const { search } = useLocation();

  useEffect(() => {
    if (!value) return;

    removeUpdateCookie();
  }, [value, removeUpdateCookie]);

  const { seoredirect } = parse(search.slice(1));
  if (seoredirect) {
    return <Redirect to={seoredirect as string} />;
  }

  return (
    <div className="container">
      <div className="container__overlay" />

      <header className="header">
        <div className="header__top">
          <div className="header__branding">
            <Link to="/">
              <img
                src={require('main/assets/images/logo-orqa-full-has-glow.svg')}
                alt="ORQA"
              />
            </Link>
          </div>
          <a
            href="https://shop.orqafpv.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn--secondary btn--sml"
          >
            Shop
          </a>
        </div>
      </header>

      <div className="header__bottom">
        <div className="header__bottom__wrapper">
          <Navigation />
          <div className="social__wrapper__mobile">
            <div className="social">
              <a
                href="https://www.facebook.com/OrqaFPV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('main/assets/images/icon-social-facebook.svg')}
                  alt="ORQA on Facebook"
                  className="header__icon"
                />
              </a>
              <a
                href="https://www.instagram.com/orqafpv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('main/assets/images/icon-social-instagram.svg')}
                  alt="ORQA on Instagram"
                  className="header__icon"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCGPZJ5j16slPTObilo8viwg/featured"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('main/assets/images/icon-social-youtube.svg')}
                  alt="ORQA on Youtube"
                  className="header__icon"
                />
              </a>
              <a
                href="https://twitter.com/OrqaFPV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('main/assets/images/icon-social-twitter.svg')}
                  alt="ORQA on Twitter"
                  className="header__icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container__content">
        <main className="container__children">{children}</main>
      </div>
      <div className="container__lightoverlay" />
    </div>
  );
};

export default Layout;
