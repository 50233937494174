import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

import { LazyImage } from './LazyImage';

interface Props {
  onClose: VoidFunction;
}

export const ManifestoModal: React.FC<Props> = ({ onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="manifesto-modal"
    >
      <motion.div
        initial={{ y: -40 }}
        animate={{ y: 0 }}
        exit={{ y: -40 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className="manifesto-modal__content"
      >
        <LazyImage
          src={require('main/assets/images/logo-orqa-symbol.svg')}
          alt="ORQA logo"
          className="s-bottom--med"
        />
        <h3 className="manifesto-modal__heading">Read the Orqa Manifesto</h3>
        <div className="manifesto-modal__buttons">
          <Link
            onClick={onClose}
            to="/blog-details/orqa-manifesto"
            className="btn btn--tny btn--primary"
          >
            Read now
          </Link>
        </div>
        <button onClick={onClose} className="manifestomodal__close btn">
          <div className="manifestomodal__x" />
        </button>
      </motion.div>
    </motion.div>
  );
};
