import Homepage from 'modules/Routing/views/Homepage';

import * as views from '../views';

export interface AppRoute {
  title: string;
  path?: string;
  Component: any;
  exact?: boolean;
  key?: number;
}

export const routes: AppRoute[] = [
  {
    title: 'Homepage',
    path: '/',
    Component: Homepage,
    exact: true,
  },
  {
    title: 'FPVOne',
    path: '/fpvone',
    Component: views.FPVOne,
    exact: true,
  },
  {
    title: 'FPVConnect',
    path: '/fpvconnect',
    Component: views.FPVConnect,
    exact: true,
  },
  {
    title: 'FPVHeadsets-details',
    path: '/fpvdetails/:id',
    Component: views.FPVDetails,
  },

  {
    title: 'About',
    path: '/about',
    Component: views.About,
    exact: true,
  },
  {
    title: 'Company Culture',
    path: '/company-culture',
    Component: views.CompanyCulture,
    exact: true,
  },
  {
    title: 'Jobs',
    path: '/jobs',
    Component: views.Jobs,
    exact: true,
  },
  {
    title: 'Download',
    path: '/download',
    Component: views.Download,
    exact: true,
  },
  {
    title: 'Resellers',
    path: '/resellers',
    Component: views.Resellers,
    exact: true,
  },
  {
    title: 'Register',
    path: '/register',
    Component: views.Register,
    exact: true,
  },
  {
    title: 'Manual',
    path: '/manual',
    Component: views.Manual,
    exact: true,
  },
  {
    title: 'Downloads',
    path: '/downloads',
    Component: views.Downloads,
    exact: true,
  },
  {
    title: 'Blog',
    path: '/blog/:filter?',
    Component: views.Blog,
    exact: true,
  },
  {
    title: 'Products',
    path: '/products/:slug?',
    Component: views.Products,
    exact: true,
  },
  {
    title: 'Blog details',
    path: '/blog-details/:slug',
    Component: views.BlogDetails,
    exact: true,
  },
  {
    title: 'Drones-simulator',
    path: '/BRINCDrones-simulator',
    Component: views.BrincDronesSimulator,
    exact: true,
  },
  {
    title: 'FPVCtrl',
    path: '/fpvctrl',
    Component: views.FPVCtrl,
    exact: true,
  },
  {
    title: 'ESIF',
    path: '/eu-projects/esif',
    Component: views.ESIF,
    exact: true,
  },
  {
    title: 'EUInternationalization',
    path: '/eu-projects/eu-Internacionalizacija',
    Component: views.EUInternationalization,
    exact: true,
  },
  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
    Component: views.PrivacyPolicy,
  },
  {
    title: 'FPVOnePilot',
    path: '/fpvonepilot',
    Component: views.FPVOnePilot,
    exact: true,
  },
  {
    title: 'FPVOneRace',
    path: '/fpvonerace',
    Component: views.FPVOneRace,
    exact: true,
  },
  { title: 'Pilots', path: '/pilots', Component: views.Pilots, exact: true },
];
